<template>
  <div class="preRequisites h-100">
    <div class="container h-100 py-5">
      <h3
        class="text-capitalize pt-2 pt-md-5"
        data-aos="fade-down-right"
        data-aos-easing="ease-in-out"
        data-aos-duration="400"
      >
        {{ this.pre_requisites.Label }}
      </h3>
      <div
        class="
          row row-wrapper
          d-flex
          align-items-center
          justify-content-center
          pb-5
        "
      >
        <div class="row pt-md-4">
          <div
            class="col-md-8 pb-2 pb-md-5"
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-duration="600"
          >
          <div class="card shadow">
            <div class="card-body p-3 p-md-5">
              <ul class="ml-2">
                <li
                  v-for="requisite in pre_requisites.prerequisites"
                  :key="requisite.id"
                  class="my-2"
                >
                  {{ requisite.value }}
                </li>
              </ul>
            </div>
          </div>
          </div>
          <div
            class="col-md-4 pb-5 pb-md-5"
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-duration="600"
          >
            <img
              src="~@/assets/images/initial_assessment.jpg"
              class="w-100 d-flex align-items-center rounded"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Pre-Requisites",
  data() {
    return {
      pre_requisites: [],
    };
  },
  methods: {
    getPreRequisites() {
      this.pre_requisites = this.$store.getters["preRequisites"];
    },
  },
  mounted() {
    this.getPreRequisites();
    this.$store.dispatch( 'setInstructions', this.$store.getters['preRequisites'].instructions )
    this.$store.dispatch( 'setStartTime')
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch( 'setEndTime' , this.$options.name)
    next()
  }
};
</script>

<style>
</style>